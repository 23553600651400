import v from "../common/variable";
import { requests } from "../common/network";
import { getTotalStarString } from "../page/page-common";
import {
	$dqs,
	setLoading,
	$dqsa,
	debounce,
	setToast,
	copyToClipboard,
} from "../common/utils";
import { handleHashchange } from "./reviews";
import svg from "../common/svg";

let slideIndex = 0;
const normalSlideWidth = 588;
let slideWidth = normalSlideWidth;
let isUploadingVideo = false;
let discountTip = "",
	photoDiscountTip = "",
	inputDebounce = null;

// Mark 嵌入幻灯片写评论弹窗
function insertSlide(d) {
	if (window.innerWidth < normalSlideWidth) {
		slideWidth = window.innerWidth;
	}
	const l = v.lang.separate_review_form;
	const discountSettingWords = v.lang.separate_review_form.discount_setting;
	const discountSetting = d.discount_setting;
	if (
		discountSetting.discount_for_photo_reviews == 1 &&
		discountSetting.discount_value &&
		(discountSetting.discount_for_reviews_form === 1 ||
			(discountSetting.discount_for_reviews_form === 2 &&
				typeof isEmailWrite !== "undefined")) // 是否为邮件邀评的情景
	) {
		for (let i in discountSettingWords) {
			discountSettingWords[i] = discountSettingWords[i].replace(
				"{{Discount_amount}}",
				discountSetting.discount_value
			);
		}

		if (discountSetting.discount_incentive_conditions === 1) {
			discountTip = `<div class="discount-tip">${discountSettingWords.tip_3}</div>`;
		} else if (discountSetting.discount_incentive_conditions === 2) {
			photoDiscountTip = `<div class="upload-desc">${discountSettingWords.tip_1}</div>`;
		}
	}
	if (discountTip !== "") {
		let high = 374;
		if (v.isMobile) {
			high += 20;
		}
		v.globalStyle.textContent += `#trustoo-slide, .write-review-slide {
			height: ${high}px !important;
	}`;
	}
	let videoHtml =
		d.enabled_add_video === 1
			? `
    <div class="slide-separate">
      <span class="slide-line"></span><span class="slide-or">${l.or}</span><span class="slide-line"></span>
    </div>
    <div class="upload-file">
      <span>${l.add_video}</span> 
      <input class="upload-input tt-video-uploader" type="file" accept=".mp4,.mkv,.mov,.avi,.flv,.ts,.ps,.mxf,.lxf,.gxf,.3gp,.webm,.mpg,.quicktime">
    </div> 
  `
			: "";
	let power = "";
	if (v.userSetting.is_del_powered !== 1) {
		power = `<div class="trustoo-power"> Powered by <a class="redirect" href="https://www.trustoo.io/" target="_blank">Trustoo.io </a> <span class="line"></span><a class="tt-power-cw" href="https://www.channelwill.com/" target="_blank">${svg.cw}</a></div>`;
	}
	const attrs = `class="stars vstar-star" data-type="${v.userSetting.rating_icon_type}"`;
	const html = `
    <div id="trustoo-slide-wrapper">
    <div id="trustoo-slide" class="trustoo-write-review trustoo-widget">
    <div class="write-review-container" style="left:0px">
      <div class="write-review-slide active" index="0">
          <div class="window-close tt-write_review_close"></div>
        <div class="slide-title">${l.slide_1_title}</div>
        <div class="slide-options">
          <div class="slide-option" rating="5"><div ${attrs}>${getTotalStarString(
		5,
		false
	)}</div>${l.rating_options[0]}</div>
          <div class="slide-option" rating="4"><div ${attrs}>${getTotalStarString(
		4,
		false
	)}</div>${l.rating_options[1]}</div>
          <div class="slide-option" rating="3"><div ${attrs}>${getTotalStarString(
		3,
		false
	)}</div>${l.rating_options[2]}</div>
          <div class="slide-option" rating="2"><div ${attrs}>${getTotalStarString(
		2,
		false
	)}</div>${l.rating_options[3]}</div>
          <div class="slide-option" rating="1"><div ${attrs}>${getTotalStarString(
		1,
		false
	)}</div>${l.rating_options[4]}</div>
        </div>
				${discountTip}
      </div>
      <div class="write-review-slide" index="1">
        <div class="slide-title">${l.slide_2_title}</div>
        <div class="slide-desc">${l.slide_2_subtitle}</div>
        <div class="slide-upload">
          ${photoDiscountTip}
          <div class="upload-file">
            <span>${l.add_photo}</span> 
            <input class="upload-input tt-image-uploader" type="file" accept=".gif,.jpeg,.jpg,.png">
          </div>
          ${videoHtml}
        </div>
        <button class="back-slide slide-button">${l.back}</button>
        <button class="slide-skip next-slide">${l.skip}</button>
      </div>
      <div class="write-review-slide" index="2">
        <div class="upload-file-tip"></div>
        <div class="slide-title">${l.slide_3_title}</div>
        <textarea maxLength="${
					v.newRevCharLimit
				}" class="slide-content" cols="30" rows="10" placeholder="${
		l.content_from_placeholder
	}"></textarea>
        <button class="back-slide slide-button">${l.back}</button>
        <button class="slide-next next-slide" disabled>${l.next}</button>
      </div>
      <div class="write-review-slide" index="3">
        <div class="slide-title">${l.slide_4_title}</div>
        <input type="text" maxLength="50" class="slide-name slide-input" placeholder="${
					l.your_name
				}">
        <input type="text" class="slide-email slide-input" placeholder="${
					l.email
				}">
        <button class="back-slide slide-button">${l.back}</button>
        <button class="slide-done next-slide" disabled>${l.done}</button>
      </div>
      <div class="write-review-slide" index="4">
					<div class="slide-title">${l.slide_5_title}</div>
					<div class="slide-desc">${l.slide_5_subtitle}</div>
					<div class="slide-discount" style="display:none">
						<span class="discount-code"></span>
						<span class="discount-copy">${l.discount_setting.copy}</span>
					</div>
					<button class="slide-finish next-slide">${l.continue}</button>
      </div>
    </div>
  </div>
	${power}
  </div>
  `;

	$dqs("#trustoo-mask").insertAdjacentHTML("beforeend", html);
	const rtlStyle =
		typeof Review !== "undefined" && ["ar", "he"].includes(Review.lang)
			? `#trustoo-slide .slide-content,#trustoo-slide .slide-input {
		direction: rtl;
	}`
			: "";

	v.globalStyle.textContent += `
	.trustoo-write-review{		--button-bd-color: ${d.review_button_background_color};
	--button-text-color: ${d.review_button_text_color};}
	.trustoo-write-review .vstar-star{
		--star-color: ${d.review_button_background_color};
		--nostar-color: ${d.review_button_text_color};
	}${rtlStyle}
	`;
	setSlideEvent();
	if (v.isLinkReqRev) {
		document.body.classList.add("trustoo-open");
		$dqs("#trustoo-mask").style.display = "flex";
		const slide = $dqs(".trustoo-write-review");
		slide.style.display = "block";
		slide.parentNode.style.display = "block";
	}
}

// Mark 添加控件事件
function setSlideEvent() {
	const slide = $dqs("#trustoo-slide");
	v.slide = slide;

	$dqs(".window-close", slide).onclick = function () {
		closeSlide();
	};
	$dqsa(".slide-option", slide).forEach(i => {
		i.onclick = function () {
			// rating = i.getAttribute('rating')
			v.ratingVal = i.getAttribute("rating");
			toNextSlide();
		};
		i.onmouseover = function () {
			i.classList.add("active");
		};
		i.onmouseout = function () {
			i.classList.remove("active");
		};
	});

	// Mark 上传图片
	$dqs(".upload-input.tt-image-uploader", slide).onchange = async function () {
		const file = this.files[0];
		const types = this.getAttribute("accept")
			.split(",")
			.map(it => {
				return it.slice(1);
			});
		const type = file.name.split(".").pop();
		if (!types.includes(type.toLowerCase())) {
			setToast(v.lang.error_tip);
			return false;
		}
		v.uploadType = 1;
		$dqs(".upload-file-tip").style.display = "block";
		$dqs(".upload-file-tip").innerHTML =
			'<span class="tt-upload_image_loading"></span>Uploading';
		toNextSlide();
		if (!Object.keys(v.uploadImgInfo).length) {
			const res = await requests.getPolicyToken();
			v.uploadImgInfo = res[1];
		}
		const fileInput = this;
		requests.uploadImg(file).then(res => {
			if (res[0] === null) {
				$dqs(".upload-file-tip").innerHTML =
					'<div class="tt-upload_image_over"></div>';
				v.mediaUrl[0] = {
					url: "https://img.trustoo.io/" + res[1].key,
					resource_type: 1,
				};
				fileInput.value = "";
			}
		});
	};
	// Mark 上传视频
	const videoUploader = $dqs(".tt-video-uploader", slide);
	if (videoUploader) {
		videoUploader.onchange = async function () {
			const file = this.files[0];
			const types = this.getAttribute("accept")
				.split(",")
				.map(it => {
					return it.slice(1);
				});
			const type = file.name.split(".").pop();
			if (!types.includes(type.toLowerCase())) {
				setToast(v.lang.error_tip);
				return false;
			}
			const mbSize = parseInt(file.size / (1024 * 1024));
			if (mbSize >= 200) {
				setToast("The file cannot exceed 200MB");
				return false;
			}
			$dqs(".upload-file-tip").style.display = "block";
			$dqs(".upload-file-tip").innerHTML =
				'<span class="tt-upload_image_loading"></span>Uploading';
			toNextSlide();
			// const len = this.files.length

			v.uploadType = 2;
			isUploadingVideo = true;
			const doneBtn = $dqs(".slide-done");
			doneBtn.setAttribute("disabled", true);
			requests
				.getUploadVideoUrl()
				.then(res => {
					const { upload_url, domain } = res[1];
					const uid = res[1].video_service_uid;
					requests
						.uploadVideo(upload_url, file)
						.then(() => {
							const link = domain + "/" + uid;
							afterUploadVideo(doneBtn, "success");
							$dqs(".upload-file-tip").innerHTML =
								'<div class="tt-upload_image_over"></div>';
							v.mediaUrl[0] = { url: link + "/iframe", resource_type: 2 };
						})
						.catch(() => {
							videoUploader.value = "";
							afterUploadVideo(doneBtn, "fail");
						});
				})
				.catch(() => {
					videoUploader.value = "";
					afterUploadVideo(doneBtn, "fail");
				});
		};
	}

	function afterUploadVideo(doneBtn, status) {
		$dqs(".upload-file-tip").innerHTML = "";
		isUploadingVideo = false;
		inputDebounce();
		if (slideIndex === 3) {
			doneBtn.textContent = v.lang.separate_review_form.done;
		}
		if (status === "fail") {
			setToast(v.lang.error_tip);
		}
	}

	// textarea标签
	const content = $dqs(".slide-content", slide);
	const nextBtn = $dqs(".slide-next", slide);
	content.oninput = function () {
		if (this.value !== "") {
			nextBtn.removeAttribute("disabled");
		} else {
			nextBtn.setAttribute("disabled", true);
		}
	};
	content.onblur = function () {
		if (this.value === "") {
			nextBtn.setAttribute("disabled", true);
		}
	};
	const reg =
		/^[a-zA-Z0-9_-]+(\.[A-Za-z0-9_-]+)*@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
	// if (!reg.test(this.value) && this.value)
	const inputs = $dqsa("input.slide-input", slide);
	const doneBtn = $dqs(".slide-done", slide);
	inputDebounce = debounce(function () {
		let isNameRight = false;
		let isEmailRight = false;
		if (inputs[1].value !== "") {
			const tip = $dqs(".input-tip", slide);
			if (!reg.test(inputs[1].value)) {
				if (!tip) {
					inputs[1].insertAdjacentHTML(
						"afterend",
						`<div class="input-tip">
            <span class="tt-error_tip"></span><span>${v.lang.email_error}</span>
          </div>`
					);
				}
			} else {
				isEmailRight = true;
				tip && $dqs(".input-tip", slide).remove();
			}
		}
		if (inputs[0].value !== "") {
			isNameRight = true;
		}
		if (isNameRight && isEmailRight && !isUploadingVideo) {
			doneBtn.removeAttribute("disabled");
		} else {
			doneBtn.setAttribute("disabled", true);
		}
	}, 300);
	inputs.forEach(i => {
		i.oninput = inputDebounce;
		i.onblur = function () {
			if (inputs[0].value === "" || inputs[1].value === "") {
				doneBtn.setAttribute("disabled", true);
			}
		};
	});

	$dqs(".discount-copy", slide).onclick = function () {
		copyToClipboard($dqs(".discount-code", slide).textContent, () => {
			setToast(v.copyText);
		});
	};

	$dqsa(".back-slide", slide).forEach(i => {
		i.onclick = function () {
			const slide = $dqs(`.write-review-slide[index="${slideIndex}"]`);
			changeActiveSlide(slide, slide.previousElementSibling);
			slideIndex--;
			$dqs(".write-review-container").style.left =
				(parseInt(slide.getAttribute("index")) - 1) * -slideWidth + "px";
		};
	});
	$dqsa(".next-slide", slide).forEach(i => {
		i.onclick = function () {
			if (i.classList.contains("slide-next")) {
				if (isUploadingVideo) {
					doneBtn.textContent = v.lang.separate_review_form.uploading_video_tip;
				}
			}
			if (i.classList.contains("slide-finish")) {
				closeSlide();
				slideIndex = 0;
				$dqsa(".slide-discount", slide).forEach(
					it => (it.style.display = "none")
				);
				$dqs(".write-review-slide.active").classList.remove("active");
				$dqs('.write-review-slide[index="0"]').classList.add("active");
				$dqs(".write-review-container").style.left = 0;
				const power = $dqs("#trustoo-mask>.trustoo-power");
				if (power) {
					power.style.display = "none";
				}
			} else if (i.classList.contains("slide-done")) {
				i.innerHTML = "";
				setLoading(i);
				uploadReview();
			} else {
				toNextSlide();
			}
		};
	});
}
// Mark 上传评论
function uploadReview() {
	const params = {};
	const slide = v.slide;
	if (v.ip) {
		params.ip = v.ip;
		params.author_country = v.loc;
	}
	const name = $dqs(".slide-name", slide),
		email = $dqs(".slide-email", slide),
		content = $dqs(".slide-content", slide);
	params.author = name.value;
	params.author_email = email.value;
	params.content = content.value;
	if (v.isLinkReqRev) {
		if (typeof isEmailWrite === "undefined") {
			params.review_source = 1;
		}
	} else {
		params.review_source = 0;
	}
	requests.uploadReview(params, v.uploadType, v.mediaUrl).then(res => {
		if (!res[0]) {
			const l = v.lang.separate_review_form;
			const code = res[1] ? res[1].discount_code : "";
			if (code) {
				const ds = l.discount_setting;
				$dqs('.write-review-slide[index="4"] .slide-title', slide).textContent =
					ds.title;
				$dqs('.write-review-slide[index="4"] .slide-desc', slide).textContent =
					ds.subtitle;
				$dqs(".slide-discount", slide).style.display = "flex";
				$dqs(".discount-code", slide).textContent = code;
			}
			toNextSlide();
			$dqsa(".slide-next,.slide-done", slide).forEach(i =>
				i.setAttribute("disabled", true)
			);
			$dqs(".upload-file-tip", slide).innerHTML = "";
			name.value = email.value = content.value = "";
			v.mediaUrl = [];
			if (v.isLinkReqRev) {
				window.history.replaceState(
					{},
					"",
					location.href.replace("?req=review", "")
				);
				v.isLinkReqRev = false;
			}
		} else {
			let msg = "";
			if (res[0].code === -2) {
				msg = res[0].error;
			} else {
				msg = v.lang.error_tip;
			}
			setToast(msg);
		}
		$dqs(".slide-done", slide).innerHTML = v.lang.separate_review_form.done;
	});
}

function closeSlide() {
	$dqs("#trustoo-mask").style.display = "none";
	const slide = $dqs("#trustoo-slide");
	slide.style.display = "none";
	slide.parentNode.style.display = "none";
	const power = $dqs("#trustoo-mask>.trustoo-power");
	if (power) {
		power.style.display = "none";
	}
	document.body.classList.remove("trustoo-open");
	window.removeEventListener("hashchange", handleHashchange);
	if (window.location.hash.indexOf("tt-write-reviews-open") !== -1) {
		window.history.replaceState(
			{},
			document.title,
			window.location.href.replace("#tt-write-reviews-open", "")
		);
	}
}

// Mark 移动到下一张
function toNextSlide() {
	const slide = $dqs(`.write-review-slide[index="${slideIndex}"]`);
	changeActiveSlide(slide, slide.nextElementSibling);
	slideIndex++;
	$dqs(".write-review-container").style.left =
		(parseInt(slide.getAttribute("index")) + 1) * -slideWidth + "px";
}

function changeActiveSlide(slide, target) {
	slide.classList.remove("active");
	target.classList.add("active");
}

export { insertSlide, inputDebounce, closeSlide };
