// ins相关
let curPage = 0,
	pageSize = 0,
	bsPageSize = 0,
	insertEl = null;

import { requests } from "../common/network";
import svg from "../common/svg";
import { $dqs, gsSession } from "../common/utils";
// customIns();
import v from "../common/variable";
import { insertReviewDetail, processRecordedData } from "../page/page-common";
// insertBuyersShow();

// Mark 构造ins展示区
async function insertBuyersShow(albumWrapper = null) {
	curPage = 0;
	let d = null;
	let position = "afterend";
	const bsBlock = $dqs("#trustoo-buyers-show-widget");
	if (!albumWrapper) {
		if (
			typeof vstar_buyers_show_settings !== "undefined" &&
			!v.isSettingUpdate
		) {
			d = vstar_buyers_show_settings;
		} else {
			const res = await requests.getBuyerShowsSettings();
			d = res[1];
		}
	}

	if ((!d || d.is_activated !== 1) && !bsBlock && !albumWrapper) return;
	if (!albumWrapper) {
		if (v.bsInsertSelector) {
			insertEl = $dqs(v.bsInsertSelector);
		} else if (bsBlock) {
			insertEl = bsBlock;
			position = "afterbegin";
		} else {
			if (
				$dqs("product-form.product-form") !== null ||
				[
					1499, 1567, 1356, 1363, 1368, 1434, 887, 1500, 1431, 1841, 1891,
				].includes(v.theme_store_id)
			) {
				insertEl = $dqs("product-form.product-form").parentNode;
			} else {
				let selector = "";
				switch (v.theme_store_id) {
					case 829: {
						selector = ".product__form-wrapper";
						break;
					}
					case 775:
					case 796: {
						selector = "form.product-form";
						break;
					}
					case 730: {
						selector = "form.product-single__form";
						break;
					}
					case 679: {
						selector = "form.addToCartForm";
						break;
					}
					case 380: {
						selector = "form.product-form--wide";
						break;
					}
					case 578: {
						selector = "form.product-form";
						break;
					}
					default: {
						if (Review.theme.name === "warehouse") {
							selector = "form.product-form";
						} else if (Review.theme.name === "Tema-plano-ouro-ldo") {
							selector = ".product-form__payment-container";
						} else {
							selector =
								"form[id^=AddToCartForm],form.product-form,product-form.product-form,form[id^=product_form]";
						}
					}
				}
				if (selector && $dqs(selector)) {
					insertEl = $dqs(selector);
				} else {
					insertEl = $dqs(".product-form");
				}
			}
		}
	}
	if (!insertEl && !albumWrapper) {
		Review.buyersShowTip = "买家秀嵌入失败";
		return;
	}

	curPage++;
	// pageSize = albumWrapper ? v.albumCount : d.columns_count * d.rows_count;

	const is = document.createElement("style");
	document.head.appendChild(is);
	v.is = is;

	if (!albumWrapper) {
		bsPageSize = d.columns_count * d.rows_count;
		v.insHoverColor = d.hover_text_color;
		v.buyersShowType = d.reviews_type === 2 ? 1 : 2;
	}

	const sortType = albumWrapper ? v.albumSortType : "image-descending";
	let key = "trustoo_show_reviews";
	if (albumWrapper) {
		if (v.isWdoRevOpen) {
			key = "trustoo_album_reviews";
		} else {
			key = "trustoo_album_all_reviews";
		}
	}

	const listHtml = await getListHTML(sortType, key);
	if (listHtml === "") {
		return;
	}
	let wrapper = null;
	if (albumWrapper) {
		wrapper = albumWrapper;
	} else {
		const existingNode = $dqs("#tt-bs-area");
		existingNode && existingNode.remove();
		const html = `<div id="tt-bs-area" style="display:none" class="trustoo-widget"><div class="tt-bs-title">${v.lang.buyers_shows}</div></div>`;
		insertEl.insertAdjacentHTML(position, html);
		wrapper = $dqs("#tt-bs-area");
	}

	if (listHtml) {
		wrapper.insertAdjacentHTML(
			"beforeend",
			`<div id="tt-bs-list">${listHtml}</div>`
		);
		v.isInsertBS = true;
		insertReviewDetail();
		$dqs("#tt-bs-list", wrapper).onclick = e => {
			const node = e.target.closest(".review-item");
			if (node) {
				const [pageIndex, itemIndex] = node.getAttribute("index").split("-");
				const list = JSON.parse(gsSession("get", "tt_detail_reviews"))[key];
				processRecordedData("reviews_click");
				TTRevDetail.showTTReviewDetail(
					v,
					"",
					list[pageIndex][itemIndex],
					albumWrapper ? "trustoo_album_reviews" : "trustoo_buyers_show"
				);
			}
		};
	} else {
		v.isNoPost = true;
	}
	if (!albumWrapper) {
		const p = (1 / d.columns_count) * 100 + "%";
		is.textContent += `
  #tt-bs-area .tt-bs-title{color:${d.title_text_color};font-size:${d.title_font_size}px;text-align:${d.title_align}}
    #tt-bs-list .tt-wrapper{flex-basis:${p};padding:${d.item_padding}px;}
    #tt-bs-list .review-item{border-radius:${d.border_radius}px;}
    `;
		$dqs("#tt-bs-area").style.display = "block";
	}
}

//Mark 构造每个动态项
async function getListHTML(sortType, key) {
	let type = v.buyersShowType;
	if (key === "trustoo_album_reviews" || key === "trustoo_album_all_reviews") {
		pageSize = v.albumCount;
		type = 2;
	} else if (key === "trustoo_show_reviews") {
		pageSize = bsPageSize;
	}
	const res = await requests.getProRevList(
		sortType,
		0,
		curPage,
		pageSize,
		type,
		true
	);

	const data = res[1];
	if (!v.lastPageSize) {
		v.lastPageSize = data.count % pageSize;
		v.total_page = data.total_page;
	}
	const list = data.list;

	const detailRev = JSON.parse(gsSession("get", "tt_detail_reviews"));
	if (detailRev[key]) {
		detailRev[key].push(list);
	} else {
		detailRev[key] = [list];
	}
	gsSession("set", "tt_detail_reviews", JSON.stringify(detailRev));
	if (curPage === 1) {
		v.insPageCount = data.total_page;
	}

	let html = "";
	list.forEach((i, index) => {
		const resource = i.resources[0];
		if (i.resources.length === 0 || (!resource.src && !resource.thumb_src)) {
			return;
		}
		const src =
			resource.resource_type === 2 ? resource.thumb_src : resource.src;
		const itemInx = curPage - 1 + "-" + index;
		const videoTipHtml =
			resource.resource_type === 2
				? `<div class="tt-video-player">${svg.video}</div>`
				: "";
		html += `<div class="tt-wrapper">
      <div class="review-item" index="${itemInx}" style="background-image:url(${src})">
			${videoTipHtml}
      </div>
    </div>`;
	});
	return html;
}

export { insertBuyersShow };
